html {
  scroll-behavior: smooth;
}

a:hover {
  cursor: pointer;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

.bread-container {
  background-color: white;
  padding: 10px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.apercu-image {
  display: inline-block;
  width: 150px;
  height: 150px;
  margin-left: 5px;
  margin-bottom: 5px;
  object-fit: cover;
}

.address_informations {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 160px;
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: 90%;
  left: 5%;
}

.toggle-btn {
  background-color: rgb(200, 200, 200);
  padding: 13px;
}

.menuTab,
.menuTabPrinc {
  min-height: 100%;
  height: auto;
  border-bottom: 1px solid black;
  transition: background-color 0.2s ease-in;
  justify-content: center;
  align-content: center;
  align-items: center;
  font-size: 1.1em;
}

.menuTab:hover {
  background-color: rgb(235, 235, 235);
}

.menuTab a,
.menuTabPrinc a {
  display: inline-block;
  height: 100%;
  width: 100%;
  text-decoration: none;
  font-family: Avenir, "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-variant: all-small-caps;
  font-weight: bold;
  padding: 15px 5px 15px 5px;
}

.menuTab {
  background-color: rgb(200, 200, 200);
}

.menuTab a {
  color: rgb(16, 72, 163);
}

.menuTabPrinc {
  background-color: rgb(16, 72, 163);
}

.menuTabPrinc a {
  color: rgb(200, 200, 200);
}

.menuTabPrinc:hover {
  background-color: rgb(36, 102, 193);
}

.item {
  opacity: 0.8 !important;
}

.item:hover {
  opacity: 1 !important;
}

.cards {
  padding-left: 20px;
  width: 90%;
}

.search-input {
  margin-bottom: 10px;
  width: 250px !important;
}

.ui.message {
  height: fit-content;
  margin-top: 23px;
}

.mailIcon {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-top: -10px;
  position: absolute;
  top: 35px;
  right: 80px;
}

.mailIcon:hover {
  cursor: pointer;
}

.newMessage {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-top: -10px;
  position: absolute;
  top: 35px;
  right: 75px;
  background-color: red;
  border-radius: 50%;
}

.card {
  background-color: #ffff;
  transition: background-color 0.05s ease-in;
}

.card p {
  font-variant: all-petite-caps;
  font-size: 1.2em;
  font-weight: bold;
  color: grey;
}

.card:hover {
  box-shadow: 0 5px 2px -3px dodgerblue;
  background-color: lightgray;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  padding-top: 5px;
  border-top: 1px solid black;
  justify-content: space-evenly;
}

.card-container .card {
  width: 290px;
  border: 1px outset lightgray;
  margin-left: 15px;
  margin-bottom: 15px;
  border-radius: 8px;
  overflow: hidden;
}

.card-container .card p {
  margin: 0;
}

.card-container .card .card-input {
  background-color: #e6e6ff;
  padding: 3px;
  text-align: right;
  height: 25%;
}

.card input[type="checkbox"] {
  display: none;
}

.card input[type="checkbox"] + label {
  display: inline-block;
  float: right;
  border: 1px solid black;
  width: 35px;
  height: 35px;
  border-radius: 3px;
  vertical-align: middle;
  margin: 5px;
  transition: background-color 0.15s ease-in;
}

.card input[type="checkbox"] + label:hover {
  cursor: pointer;
}

.card input[type="checkbox"]:checked + label::after {
  content: "✔";
  display: inline-block;
  font-size: 2em;
  color: white;
  position: relative;
  left: 6px;
  top: 7px;
}

.card input[type="checkbox"]:checked + label {
  background-color: dodgerblue;
}

.card-container .card .card-content {
  padding: 10px;
}

.card-container .card .card-content:hover {
  cursor: pointer;
}

.cardlist {
  width: 100%;
  min-height: 40px;
  border-bottom: 1px solid lightblue;
  line-height: 40px;
  display: flex;
  justify-content: right;
}

.cardlist:nth-child(even) {
  background-color: lightgray;
}

.cardlist-content {
  width: 100%;
}

.cardlist-content > div {
  align-self: center;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  max-width: 93%;
  margin-left: 40px;
  min-width: 377px;
  min-height: 40px;
  margin-top: auto;
  line-height: 40px;
}

.cardlist-content:hover {
  cursor: pointer;
  background-color: lightblue;
}

.cardlist-content > div h3,
.cardlist-content > div p {
  margin: 0;
  padding-top: 5px;
  padding-bottom: 5px;
  min-height: 20px;
  align-self: center;
}

.cardlist input[type="checkbox"] {
  display: none;
  align-self: center;
}

.cardlist input[type="checkbox"] + label {
  display: inline-block;
  float: left;
  position: absolute;
  border: 1px solid black;
  width: 35px;
  height: 35px;
  border-radius: 3px;
  transition: background-color 0.15s ease-in;
  align-self: center;
}

.cardlist input[type="checkbox"] + label:hover {
  cursor: pointer;
}

.cardlist input[type="checkbox"]:checked + label::after {
  content: "✔";
  display: inline-block;
  font-size: 2em;
  color: white;
  position: relative;
  left: 6px;
}

.cardlist input[type="checkbox"]:checked + label {
  background-color: dodgerblue;
}

.costItemOptions {
  display: flex;
}

#btnflipCam {
  position: absolute;
  display: inline-block;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  padding: 0;
  left: 75%;
  margin-left: -25px;
  bottom: 40px;
}

#btnPhoto {
  position: absolute;
  display: inline-block;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  padding: 0;
  left: 50%;
  margin-left: -25px;
  bottom: 15px;
}

#btnPhoto::before {
  content: "";
  display: inline-block;
  height: 55px;
  width: 55px;
  border-radius: 50%;
  border: 2px solid white;
  margin-top: 2px;
}

#btnPhoto div {
  position: relative;
  top: -45px;
  left: 2px;
}

#webcam {
  background-color: black;
  text-align: center;
  z-index: 1000;
}

#snapshot {
  background-color: black;
  align-content: center;
  justify-content: center;
  text-align: center;
}

#result-snapshot {
  margin: auto;
}

#snapshot-confirm {
  justify-content: center;
  position: absolute;
  z-index: 1000;
  width: 7%;
  left: 47%;
  bottom: 50px;
  background-color: rgba(250, 250, 250, 0.6);
  padding: 8px;
  border-radius: 10px;
}

#snapshot-confirm button:nth-child(1) {
  margin-bottom: 8px;
}

.logo {
  height: 70px;
  display: block;
}

.logo-background {
  height: 70px;
  display: flex;
  background-color: white;
  justify-content: space-between;
}

.logo-app {
  height: 70px;
}

.logoHomePage {
  height: 50px;
  margin: 15px;
}

.warning {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border: 1px solid #faebcc;
  margin: 0;
  padding: 5px;
}

.fill {
  min-height: 80vh;
  width: 100%;
  margin-bottom: 45px;
  position: relative;
}

.error-message {
  padding: 4px;
  background-color: rgba(200, 100, 100, 0.6);
  border: 1px solid red;
  font-weight: bold;
  margin-right: 25px;
}

.success {
  padding: 4px;
  background-color: rgba(100, 200, 100, 0.6);
  border: 1px solid green;
  font-weight: bold;
  margin-right: 25px;
}

.error-required input {
  border: 1px solid red !important;
}

.full {
  min-height: 89vh;
}

.ui.segment.pushable {
  margin-top: 0 !important;
  min-height: 89vh;
}

.crudBtns {
  display: inline-block;
  height: 36px;
  width: 84%;
  bottom: 10px;
  margin: 8px auto;
  transition: left 0.5s ease;
}

.crudBtns .btnSave {
  background-color: rgba(0, 153, 0, 0.9);
  transition: background-color 0.1s ease-in;
  position: absolute;
  right: 0;
  color: white;
}

.crudBtns .btnDelete {
  position: absolute;
  right: 115px;
}

.crudBtns .btnSave:hover {
  background-color: rgba(0, 153, 0, 1);
  color: white;
}

.option {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: black;
  min-height: 35px;
  text-align: left;
  border-bottom: 1px solid black;
  border-left: 1px solid black;
  margin-left: -1px;
}

.option:hover > h3,
.option:hover > span {
  cursor: pointer;
  transition: background-color 0.5s ease;
}

.option:hover > h3 {
  background-color: rgb(0, 194, 227);
}

.option:hover > span {
  background-color: rgba(255, 255, 255, 0.5);
  left: -12px;
}

.option h3 {
  display: inline-block;
  width: 80%;
  min-height: 34px;
  background-color: #00a9c6;
  margin: 0;
  text-align: center;
}

.option a {
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 5px;
  min-height: 34px;
}

.option-assets h3 {
  width: 100%;
}

.arrow {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-top: 3px solid #fff;
  border-right: 3px solid #fff;
  position: relative;
  left: -15px;
  transition: left 0.2s ease;
}

.arrow-right {
  transform: rotate(45deg);
}

.menuTitle {
  background-color: lightgray;
  color: #000;
  margin: 0;
  padding: 5px;
}

.menuTitle:hover {
  cursor: pointer;
}

.btnQR {
  position: absolute;
  right: 0;
  bottom: calc(0.785rem / 2);
}

.ui.form .disabled.field,
.ui.form .disabled.fields .field,
.ui.form .field :disabled {
  border-color: transparent !important;
  color: black !important;
  opacity: 1 !important;
}

.ui.form .disabled.field,
.ui.form .disabled.fields .field,
.ui.form .field :disabled {
  cursor: default;
}

.ui.form .field.disabled > label,
.ui.form .fields.disabled > label {
  opacity: 1 !important;
}

.label-disabled {
  color: rgba(0, 0, 0, 0.4);
}

.ui.form.transparent {
  pointer-events: none !important;
}

.ui.table tr.positive {
  background: #b9ebff !important;
  color: #2c662d !important;
}

.newIndicator {
  float: right;
}

.tbl-row:hover {
  cursor: pointer;
  background-color: rgb(220, 220, 220);
}

.hide {
  display: none;
}

.sound-wave {
  display: inline-block;
  width: 40%;
  position: fixed;
  bottom: 60px;
  left: 30%;
  border-radius: 10px;
  height: 60px;
}

.circle {
  stroke-width: 2.8;
  animation: progress 1s ease-out forwards;
  pointer-events: none;
}

.percentage-line {
  animation: progress-line 1s ease-out forwards;
  pointer-events: none;
}

@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}

@keyframes progress-line {
  0% {
    x2: 0 500;
  }
}

tr .completed {
  color: green;
  font-weight: bold;
}

@media only screen and (max-width: 767px) {
  .ui.table:not(.unstackable) tr > td,
  .ui.table:not(.unstackable) tr > th {
    text-align: left !important;
  }
}

.field > .action-name-container {
  display: flex;
  align-items: center;
}

.field > .action-name-container > p {
  flex-grow: 0.75;
  margin: 0;
  max-width: 500px;
}

.action-name-suffix {
  align-items: center;
  display: flex;
  flex-direction: column;
}
.action-name-suffix > .field > label {
  width: 100% !important;
  text-align: left !important;
}
.action-name-suffix > .field > .ui.input > input {
  min-width: 250px;
}

div.ant-picker {
  border: none;
  width: 100%;
  padding: 0;
}
a.list-items {
  color: black !important;
}
