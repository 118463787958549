#root {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

html,
body {
  height: 100%;

  display: flex;
  flex-direction: column;
}

body > * {
  flex-shrink: 0;
}
