.display-none {
  display: none;
}

.copyComponentForm {
  position: absolute;
  color: white;
  height: 70%;
  width: 85%;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.8);
  z-index: 1;
  border-radius: 10px;
  border: 2px solid;
  padding: 35px;
}

.copyComponentForm .ui.form .field > label {
  color: white;
}

.descriptionField {
  min-width: 100%;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  text-align: left;
  line-height: 1.21428571em;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  padding: 0.67857143em 1em;
  background: #fff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;
  transition: box-shadow 0.1s ease, border-color 0.1s ease;
  box-shadow: none;
}

.rsMeansSection {
  padding-top: 3%;
}
