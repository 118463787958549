.ui.inverted.menu {
  background-color: #00a9c6;
  box-shadow: 7px -1px 11px -4px rgba(132, 134, 135, 1) !important;
}

.toggle-btn {
  background-color: white;
}

.menulevel {
  position: relative;
  left: 182px;
  top: -34px;
  z-index: 2000;
  width: 150px;
  background-color: #000;
}

.menulevel > div {
  height: 35px;
}

.menulevel > p {
  background-color: lightgray;
  color: black;
  margin: 0;
  padding: 5px;
  text-align: center;
  height: 35px;
  line-height: 35px;
  font-weight: bold;
  font-size: 1.8em;
}

.ui.sidebar,
.menulevel {
  overflow: visible !important;
}
