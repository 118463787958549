.credentialsErrorMessage {
  color: red;
  font-weight: bold;
  font: 16px "Helvetica";
}

.login-background {
  background: linear-gradient(to right, #027997 0%, #3ba8c6b5 100%);
  position: absolute;
  height: 100vh;
  top: 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;
  justify-content: space-evenly;
  flex-direction: column;
}

.logo_holder img {
  max-height: 200px;
}
